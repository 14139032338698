.home-container {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.intro-section {
  text-align: center;
  margin-bottom: 50px;
}

.profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.highlight-section {
  text-align: center;
}

.highlight-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.highlight-item {
  flex: 1;
  min-width: 250px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.highlight-item:hover {
  transform: scale(1.05);
}

.highlight-img {
  max-width: 100%;
  height: auto;
  max-height: 200px;
  margin-bottom: 20px;
  object-fit: cover;
  border-radius: 10px;
}

h1, h2, h3 {
  color: #333;
}

p {
  color: #666;
}
