.podcast-container {
  font-family: 'Arial', sans-serif;
  color: #fff;
}

.parallax-section {
  min-height: 500px; 
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#section1 {
  background-image: url('/public/Media/Photos/Background/IndexWall.jpg'); 
}

#section2 {
  background-image: url('/public/Media/Photos/Background/Skiing5.jpg'); 
}

/* ... Add more IDs or classes for each background image ... */

.content-container {
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
}

.white-space {
  height: 300px;
  background: #fff;
}
